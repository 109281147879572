require('./index.scss');

// Nav links
const navLink = document.querySelector('.section--item');
const buttonBook = document.querySelector(".contract-button--select");

// Mobile Read More
const mobileReadMore = document.querySelector('.mobile-read--more');
const userInput = document.getElementById("mobile");

// Auto Populate Email Section
const subjectLine = document.getElementById('SubjectLine');
const subjectSub = document.getElementById("beginner-package");
const extraInfo = document.querySelector('.extra-info');
const selectExtra = document.querySelectorAll(".select-extra-package");
const skypeHandle = document.getElementById("skypeHandle");
const bboHandle = document.getElementById("bboHandle");

// Book now level change
const level = document.getElementById('level-selector');

const breakpoint = 992;
const winWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

navLink.addEventListener('click', (evt) => { 

    const navLinks = document.querySelectorAll('.section-item--link');
    const state = evt.target.offsetParent.classList.contains('active');

    navLinks.forEach((nav) => {

        if(nav.classList.contains('is-active')){
            nav.classList.remove('is-active');
        }

    })
    setClassList(state, evt.target.offsetParent);
})

// Mobile Read More
if(winWidth < breakpoint){
    
    const readMoreButton = document.querySelector('.read-button');

    readMoreButton.addEventListener("click", () => {

        const state = mobileReadMore.classList.contains('is-active');
        setClassList(state, mobileReadMore);
        return state ? readMoreButton.textContent = "Read More" : readMoreButton.textContent = "Read Less";;

    });

};

// Book now level change
level.onchange = function () {
    buttonBook.textContent = `Book Now as ${this.value}`;
    buttonBook.rev = `book now for the ${ buttonBook.textContent } level`;
}

// Auto Populate Email Section (listen on each book now button and set field appropritaly)
selectExtra.forEach(select => { 
    select.addEventListener("click", evt => { 
        setForm(select);
    })
});

function setForm(selected) { 

    subjectLine.value = `I would like to ${selected.rev}`;
    setClassList(state = selected.id !== "BBO", extraInfo);
    if(!state){
        skypeHandle.required = true;
        bboHandle.required = true;
    } else { 
        skypeHandle.required = false;
        skypeHandle.name = "";
        bboHandle.required = false;
        bboHandle.name = "";
    }
    setClassList(state = !(selected.rev).includes('Beginner'), subjectSub);

}

// Mobile input regex listner 
userInput.addEventListener("input",  () => {

    const string = validateCode(userInput.value);
    if(userInput.value.length < 10) { 

        userInput.value = string;

    }

});

const validateCode = (string) => {

    let str = string.split("-").join("");

    if(str.length > 0){

        str = str.match(new RegExp(".{1,3}", "g")).join("-");

    }

    return str.toUpperCase();

}

// global add and remove class 
function setClassList(state , id) { 

    return state ? id.classList.remove('is-active') : id.classList.add('is-active');

};